import React from "react";
import './Gellery.css';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

function Profile() {
  const { t } = useTranslation();

  return (
    <div className="gellery">
     <div className="gellery-tab" >
        <img src="img/메인갤러리.png" alt="gallely" className="gellery-main" />
        <strong>Signature Id Picture</strong>
        <Link to="/profile">Portrait</Link>
        <Link to="/snap">Film</Link>
        <Link to="/body">Body Portrait</Link>
        <Link to="/couple">Couple</Link>
        {/* <Link to="/family">Family</Link> */}
        <Link to="/animal">Animal</Link>
      </div>
      <div className="profile-ex">
        <Link to="/signature1"><img src={t('signature1')} alt="urbanmix" /></Link>
        <Link to="/signature2"><img src={t('signature2')} alt="urbanmix" /></Link>
      </div>
    </div>
  );
}

export default Profile;
