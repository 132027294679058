import React from "react";
import "./Footer.css";

function Footer() {
  return (
    <footer className="footer">
      <img src="img/footer_i.jpg" alt="footer" className="footer-image"/>
      <div className="hidden-text">증명사진, 여권사진, 프로필, 가족사진, 취업사진, 반려동물사진, 웨딩사진, 스냅사진, 야외촬영 전문 사진관</div>
    </footer>
  );
}

export default Footer;
