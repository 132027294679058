import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Header.css";
import { HiMiniBars3 } from 'react-icons/hi2';
import { useTranslation } from 'react-i18next';
import LocalStorages from '../etc/LocalStorages';
import '../etc/i18n';

function Header() {
  const [showDropdown, setShowDropdown] = useState(false); //갤러리 드롭다운
  const [isMenuOpen, setIsMenuOpen] = useState(false); //슬라이드 메뉴
  const location = useLocation();
  const isHome = location.pathname === '/';
  const [languageDropdown, setLanguageDropdown] = useState(false); //언어 드롭다운
  const { i18n } = useTranslation();

  const toggleDropdown = () => {  //갤러리 드롭다운
    setShowDropdown(!showDropdown);
  }

  const toggleLanguage = () => {  //언어 드롭다운
    setLanguageDropdown(!languageDropdown);
  }

  const toggleMenu = () => {  //슬라이드 메뉴
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const savedLang = localStorage.getItem(LocalStorages.language);
    if (savedLang) {
      i18n.changeLanguage(savedLang);
    }
  }, [i18n]);

  // 전체 화면 클릭 시 메뉴 닫기
  useEffect(() => {
    const closeMenuOnOutsideClick = (e) => {
      if (!e.target.closest('.mobile-menu') && !e.target.closest('.hamburger')) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('click', closeMenuOnOutsideClick);

    return () => {
      document.removeEventListener('click', closeMenuOnOutsideClick);
    };
  }, []);

  const handleLanguageChange = (value) => {
    localStorage.setItem(LocalStorages.language, value);
    i18n.changeLanguage(value);
  };

  return (
    <div className={`header ${isHome ? "" : "header-dark"}`}>

      <div className="hamburger" onClick={toggleMenu}>
        <HiMiniBars3 size={25} color="white" />
      </div>

      {/* 모바일 메뉴 */}
      <div className={`mobile-menu ${isMenuOpen ? 'menu-open' : ''}`} onClick={toggleMenu}>
        <Link to="/intro">Introduce</Link>
        <Link to="/reserve">Reserve</Link>
        <Link to="/price">Price</Link>
        <Link to="/profile">Gallery</Link>
        <Link to="/signature" className="sub-menu">Signature Id Picture</Link>
        <Link to="/profile" className="sub-menu">Portrait</Link>
        <Link to="/snap" className="sub-menu">Film</Link>
        <Link to="/body" className="sub-menu">Body Portrait</Link>
        <Link to="/couple" className="sub-menu">Couple</Link>
        {/* <Link to="/family" className="sub-menu">Family</Link> */}
        <Link to="/animal" className="sub-menu">Animal</Link>
        <a href="http://pf.kakao.com/_zgxkNb" target="_blank" rel="noopener noreferrer">Support</a>
        <div style={{ marginLeft: '20px', marginTop: '30px' }}> Language </div>
        <div onClick={() => handleLanguageChange('ko')} style={{ marginLeft: '40px', marginTop: '20px' }} > Korean </div>
        <div onClick={() => handleLanguageChange('en')} style={{ marginLeft: '40px', marginTop: '20px' }} > English </div>
        <div onClick={() => handleLanguageChange('ja')} style={{ marginLeft: '40px', marginTop: '20px' }} > Japanese </div>
      </div>

      <Link to="/"><img src="img/urbanmix.png" alt="urbanmix" className="urbanmix-img" /></Link>

      <div className="header-flex">
        <Link to="/intro">Introduce</Link>
        <Link to="/reserve">Reserve</Link>
        <Link to="/price">Price</Link>

        {/* 갤러리 드롭다운 시작 */}
        <div onMouseEnter={toggleDropdown} onMouseLeave={toggleDropdown}>
          <Link to="/signature" >Gallery</Link>

          {showDropdown && (
            <div className={`dropdown-menu ${isHome ? "dropdown-transparent" : ""}`}>
              <Link to="/signature">Signature Id Picture</Link>
              <Link to="/profile">Portrait</Link>
              <Link to="/snap">Film</Link>
              <Link to="/body">Body Portrait</Link>
              <Link to="/couple">Couple</Link>
              {/* <Link to="/family">Family</Link> */}
              <Link to="/animal">Animal</Link>
            </div>
          )}
        </div>
        {/* 갤러리 드롭다운 끝 */}

        <a href="http://pf.kakao.com/_zgxkNb" target="_blank" rel="noopener noreferrer">Support</a>

        {/* 언어 드롭다운 시작 */}
        <div className="language-dropdown" onMouseEnter={toggleLanguage} onMouseLeave={toggleLanguage}>
          {localStorage.getItem(LocalStorages.language) === "ko" ? (
            <div className="language-flex">
              <img src="img/language/korea.png" alt="Korea" />
              <span>Korean</span>
            </div>
          ) : localStorage.getItem('language') === 'en' ? (
            <div className="language-flex">
              <img src="img/language/english.png" alt="English" />
              <span>English</span>
            </div>
          ) : (
            <div className="language-flex">
              <img src="img/language/japan.png" alt="Japanese" />
              <span>Japanese</span>
            </div>
          )}
          {languageDropdown && (
            <div className={`dropdown-menu ${isHome ? "dropdown-transparent" : ""}`}>
              <div className="language-option" onClick={() => handleLanguageChange("ko")}>
                <img src="img/language/korea.png" alt="Korean" />
                <span>Korean</span>
              </div>
              <div className="language-option" onClick={() => handleLanguageChange("en")}>
                <img src="img/language/english.png" alt="English" />
                <span>English</span>
              </div>
              <div className="language-option" onClick={() => handleLanguageChange("ja")}>
                <img src="img/language/japan.png" alt="Japanese" />
                <span>Japanese</span>
              </div>
            </div>
          )}
        </div>
        {/* 언어 드롭다운 끝 */}

      </div>
    </div>
  );
}

export default Header;