import React from "react";
import './Gellery.css';
import { Link } from "react-router-dom";

function Profile() {

  return (
    <div className="gellery">
        <div className="gellery-tab" >
        <img src="img/메인갤러리.png" alt="gallely" className="gellery-main" />
        <Link to="/signature">Signature Id Picture</Link>
        <Link to="/profile">Portrait</Link>
        <Link to="/snap">Film</Link>
        <Link to="/body">Body Portrait</Link>
        <Link to="/couple">Couple</Link>
        {/* <Link to="/family">Family</Link> */}
        <strong>Animal</strong>
      </div>
      <div className="profile-ex">
        <Link to="/animal1"><img src="img/GelleryImage/animal1.jpg" alt="urbanmix" /></Link>
        <Link to="/animal2"><img src="img/GelleryImage/animal2.jpg" alt="urbanmix" /></Link>
      </div>
    </div>
  );
}

export default Profile;
