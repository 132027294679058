import React from "react";
import './Reserve.css';
import { useTranslation } from 'react-i18next';


function Reserve() {
  const { t } = useTranslation();

  const handleButtonClick = () => {
    window.location.href = 'https://m.booking.naver.com/booking/13/bizes/795306';
  };

  return (
    <div className="reserve">
      <img src={t('reservation')} alt="예약" style={{ marginTop: '10%' }} />
      <button onClick={handleButtonClick} >{t('reserve')}</button>
    </div>
  );
}

export default Reserve;
