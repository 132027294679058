import React from "react";
import './Gellery.css';
import { Link } from "react-router-dom";

function Snap() {
  // const [saltMouseOver, setSaltMouseOver] = useState(false);

  return (
    <div className="gellery">
      <div className="gellery-tab" >
        <img src="img/메인갤러리.png" alt="gallely" className="gellery-main" />
        <Link to="/signature">Signature Id Picture</Link>
        <Link to="/profile">Portrait</Link>
        <strong>Film</strong>
        <Link to="/body">Body Portrait</Link>
        <Link to="/couple">Couple</Link>
        {/* <Link to="/family">Family</Link> */}
        <Link to="/animal">Animal</Link>
      </div>
      <div className="profile-container">
        {/* <Link to="/snap1"><img src={saltMouseOver ? 소금i : 소금} alt="urbanmix" onMouseOver={() => setSaltMouseOver(true)} onMouseOut={() => setSaltMouseOver(false)} /></Link> */}
        <Link to="/snap1"><img src="img/GelleryImage/소금.jpg" alt="urbanmix" /></Link>
        <Link to="/snap2"><img src="img/GelleryImage/미지.jpg" alt="urbanmix" /></Link>
      </div>
    </div>
  );
}

export default Snap;
