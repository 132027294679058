import React from "react";
import './ProfileDetail.css';


function ProfileDetail() {

  return (
    <div className="profileDetail">
      
    </div>
  );
}

export default ProfileDetail;
